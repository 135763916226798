const SESSION_KEY = 'loggedInUser';
const AUTHTOKEN_KEY = 'authtoken';
const ANONYMOUS_USER_SESSION_KEY = 'anonymousUser';

export const saveLoggedInUser = (user) => {
  sessionStorage.setItem(SESSION_KEY, JSON.stringify(user));
};

export const getLoggedInUser = () => {
  const user = sessionStorage.getItem(SESSION_KEY);
  return user ? JSON.parse(user) : null;
};

export const removeLoggedInUser = () => {
  sessionStorage.removeItem(SESSION_KEY);
  sessionStorage.removeItem(AUTHTOKEN_KEY);
};

export const saveAnonymous = (userAnonymus) => {
  sessionStorage.setItem(ANONYMOUS_USER_SESSION_KEY, JSON.stringify(userAnonymus));
}

export const getAnonymousUser = () => {
  const anonymous = sessionStorage.getItem(ANONYMOUS_USER_SESSION_KEY);
  return anonymous ? JSON.parse(anonymous) : null;
};

export const removeAnonymousUser = () => {
  sessionStorage.removeItem(ANONYMOUS_USER_SESSION_KEY);
};

export const saveAuthToken = (token) => {
  sessionStorage.setItem(AUTHTOKEN_KEY, token);
};

export const getAuthToken = () => {
  return sessionStorage.getItem(AUTHTOKEN_KEY);
};