import { useEffect, useState } from "react";
import "./usersList.scss";
import Axios from "axios";

import { AiTwotoneEdit } from "react-icons/ai";
import ModalEditRank from "../ModalEditRank/ModalEditRank"
import { getRankName } from "../Functions/utils";
import withAuth from "../Contexts/withAuth";
import APIHOST from "../../constants";

const ICON_SIZE = 14;

function UsersTable() {
  const [usersToTable, setUsersToTable] = useState([]);
  const [modalShowRank, setModalShowRank] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);

  //callbacks
  const getUsers = () => {
    Axios.get(`${APIHOST}/getUsersListToTable`).then((Response) => {
      setUsersToTable(Response.data);
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const openModalToRank = (value) => {
    setSelectedUser(value);
    setModalShowRank(true);
  };

  //function to format the date and time
  const formarDateTime = (dateTimeString) => {
    return new Date(dateTimeString).toLocaleString("pt-PT");
  };

  return (
    <>
      <ModalEditRank
        show={modalShowRank}
        onHide={() => setModalShowRank(false)}
        backdrop="static"
        callback={getUsers}
        info={selectedUser}
      />
      <table width="100%" className="mt-5">
        <thead className="colorHead">
          <tr>
            <th>Email</th>
            <th>Rank</th>
            <th>Data de criação</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {usersToTable.map((value) => (
            <tr key={value.id} className="tableTr">
              <td>{value.email}</td>
              <td>{getRankName(value.rank)}</td>
              <td>{formarDateTime(value.created_at)}</td>
              <td>
                <button
                  className="btnNormal"
                  onClick={() => openModalToRank(value)}
                >
                Editar rank
                  <AiTwotoneEdit style={{marginLeft: '8px'}} size={ICON_SIZE} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
        {/* <tfoot>
          <tr>
            <td>Total 2</td>
            <td>Total 3</td>
          </tr>
        </tfoot> */}
      </table>
    </>
  );
}

export default withAuth(UsersTable);
