import react from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import UsersTable from "../../components/UsersList/UsersList";

function UsersPage() {
  return (
    <div className="whiteColor" style={{height: '100vh'}}>
      <Container style={{ maxWidth: "1700px" }}>
        <Row>
          <Col md={12}>
            <UsersTable />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default UsersPage;
