import React from "react";
import "./UserComponent.css";
import Dropdown from "react-bootstrap/Dropdown";
//import { getLoggedInUser } from '../Contexts/auth';
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ButtonOut from "../LogOutButton/ButtonOut";
import ModalEditUser from "../ModalEditUser/ModalEditUser";

function UserComponent(props) {

  const [modalShowEditUser, setModalShowEditUser] = useState(false)
  

  function logOutCallBack() {
    setLoggedInUser(null);
  }

  const [loggedInUser, setLoggedInUser] = useState(
    JSON.parse(sessionStorage.getItem("loggedInUser"))
  );
  const location = useLocation();

  useEffect(() => {
    const newLoggedInUser = location.state?.loggedInUser;
    if (newLoggedInUser) {
      setLoggedInUser(newLoggedInUser);
    } else {
      setLoggedInUser(JSON.parse(sessionStorage.getItem("loggedInUser")));
    }
  }, [location.state]);

  return (
    <>
      <ModalEditUser
        show={modalShowEditUser}
        onHide={() => setModalShowEditUser(false)}
        backdrop="static"
        
      />
      <div>
        {loggedInUser && (
          <Dropdown>
            <Dropdown.Toggle className="teste">
              <small>{loggedInUser.email}</small>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/* <button className="btn-out" onClick={() => setModalShowEditUser(true)}>Dados pessoais</button> */}
              <ButtonOut callback={logOutCallBack} />
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    </>
  );
}

export default UserComponent;
