import "./Login.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Axios from "axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import APIHOST from "../../constants";
import { saveAnonymous } from "../../components/Contexts/auth";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";

function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  //salvar o user logado no sessionstorage
  function saveLoggedInUser(userData) {
    sessionStorage.setItem("loggedInUser", JSON.stringify(userData));
  }

  function handleSubmit(event) {
    event.preventDefault(); // Prevent the default form submission behavior
    // Clear any previous error messages
    setError(null);
    // Check if email and password are not empty
    if (!email || !password) {
      setError("É necessário inserir o email e a password do Outlook");
      return;
    }
    // Create an object with the email and password
    const requestData = {
      email,
      password,
    };
    // Make a POST request to your server's /executePython endpoint
    Axios.post(`${APIHOST}/loginWithPython`, requestData)
      .then((response) => {
        const data = response.data;
        const status = response.status; // Get the HTTP status code

        if (status === 200) {
          //Store the logged-in user's information in session storage
          saveLoggedInUser(data);

          // Navigate to the homepage
          navigate("/pages/Home");

          // Fetch the list of apps
          window.location.reload();
        } else {
          setError("Autenticação errada, tente novamente");
        }
      })
      .catch((error) => {
        console.error("Error executing Python script:", error);
        setError("Autenticação errada, tente novamente");
      });
  }

  const anonymous = () => {
    const anonymousUserData = {
      username: "anonymous",
      role: "guest",
    };
    saveAnonymous(anonymousUserData);
    navigate("/pages/Home")
    window.location.reload();
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="h-100">
      <div className="d-flex justify-content-center align-items-center height100">
        <form className="formLogin" onSubmit={handleSubmit}>
          <div>
            <h4 className="mb-3">HFA Intranet</h4>
            <p className="titleLogin mb-5">
              Faça Login para descobrir outras funcionalidades
            </p>
          </div>
          <div>
            <input
              className="inputBoxs"
              type="email"
              autoFocus
              name="email"
              required
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e)}
            />
          </div>
          <div style={{position: 'relative'}}>
            <input
              className="inputBoxs"
              type={showPassword ? 'text' : 'password'}
              autoComplete
              required
              name="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e)}
            />
            <button type="button" style={{ position: 'absolute', top: '6px', right: '16px', background: 'none', border: 'none' }} onClick={handleTogglePassword}>
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          {error && (
            <div>
              <small>{error}</small>
            </div>
          )}
          <button className="btnEdit mt-3 w-100">Entrar</button>
          <div style={{ marginTop: '8px' }}>
            <Link onClick={anonymous} style={{ textDecoration: 'none', color: 'black', fontSize: '14px' }} to="/pages/Home">Entrar como anónimo</Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
