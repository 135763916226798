import React, { useState, useEffect } from 'react';
import './ModalEditUser.css';
import Modal from 'react-bootstrap/Modal';
import Axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { toast } from 'react-toastify';
import APIHOST from '../../constants';

function ModalEditUser(props) {

  // eslint-disable-next-line no-unused-vars
  const [loggedInUser, setLoggedInUser] = useState(
    JSON.parse(sessionStorage.getItem("loggedInUser"))
  );

  const [values, setValues] = useState({
    Nome: '',
    password: '',
    Email: '',
    Departamento: '',
    ContactoInterno: '',
    ContactoExterno: '',
    DepartamentoID: '',
    ...loggedInUser,
  });

  useEffect(() => {
    if (loggedInUser) {
      setValues((prevValues) => ({
        ...prevValues,
        Nome: loggedInUser.name || '',
        Email: loggedInUser.email || '',
        DepartamentoID: loggedInUser.Departamento || '',
        // Add other properties from loggedInUser if needed
      }));
    }
  }, [loggedInUser]);

  
  const handleChangeValues = (event) => {
    const { name, value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleClickButton = () => {
    Axios.put(`${APIHOST}/edituser`, values) // Use PUT instead of POST
      .then((response) => {
        if (response.status === 200) {
          toast.success('Dados alterados com sucesso!');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  
    props.onHide();
  };

  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await Axios.get(`${APIHOST}/departs`);
        setOptions(response.data);
      } catch (error) {
        // Handle error
        console.error(error);
      }
    }

    fetchData();
  }, []);

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <h4 className="mb-0">Editar dados do utilizador</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="d-grid">
          <Row>
            <Col md={6}>
              <label className="labelTitle">Nome do colaborador</label>
              <input
                className="inputBoxs"
                type="text"
                name="Nome"
                placeholder="Inserir nome"
                value={values.Nome}
                onChange={handleChangeValues}
              />
            </Col>
            <Col md={6}>
              <label className="labelTitle">Password</label>
              <input
                className="inputBoxs"
                type="password"
                name="password"
                placeholder="Inserir password"
                value={values.password}
                onChange={handleChangeValues}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <label className="labelTitle">Email do colaborador</label>
              <input
                className="inputBoxs"
                type="email"
                name="Email"
                placeholder="Inserir email"
                defaultValue={values.Email}
                value={values.Email}
                onChange={handleChangeValues}
              />
            </Col>
            <Col md={6}>
              <label className="labelTitle">Contacto Interno</label>
              <input
                className="inputBoxs"
                type="text"
                name="ContactoInterno"
                placeholder="Inserir contacto interno"
                value={values.ContactoInterno}
                onChange={handleChangeValues}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
            <label className="labelTitle">Contacto Externo</label>
            <input
              className="inputBoxs"
              type="text"
              name="ContactoExterno"
              placeholder="Inserir contacto Externo"
              value={values.ContactoExterno}
              onChange={handleChangeValues}
            />
          </Col>
          <Col md={6}>
            <label className="labelTitle">Departamento</label>
            <select
                name="DepartamentoID"
                id="DepartamentoID"
                defaultValue={values.Departamento}
                value={values.idDepartamento}
                className="optDeparts"
                onChange={handleChangeValues}
              >
                {options.map((option) => (
                  <option
                    key={option.idDepartamento}
                    value={option.idDepartamento}
                  >
                    {option.Nome}
                  </option>
                ))}
              </select>
          </Col>
        </Row>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <button className="btnSave" onClick={handleClickButton}>Guardar alterações</button>
      <button className="btnCancel" onClick={props.onHide}>Cancelar</button>
    </Modal.Footer>
  </Modal>
);
}

export default ModalEditUser;