import React, { useState } from 'react';
import './ModalAddApp.css';
import Modal from 'react-bootstrap/Modal';
import Axios from 'axios';
import { toast } from 'react-toastify';
import APIHOST from '../../constants';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';



function ModalAddApp(props) {
  const [values, setValues] = useState({
    title: '',
    description: '',
    link: '',
    pos: '',
    color: '#ffffff' // Initial color value
  });

  const handleChangeValues = (e) => {
    setValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value
    }));
  };

  const handleColorChange = (e) => {
    setValues((prevValues) => ({
      ...prevValues,
      color: e.target.value || '#ffffff' // Set default color value
    }));
  };

  const handleClickButtonApp = () => {
    Axios.post(`${APIHOST}/registerapp`, {
      title: values.title,
      description: values.description,
      link: values.link,
      pos: values.pos,
      color: values.color
    })
      .then((response) => {
        if (response.status === 200) {
          props.callbackapps();
          props.callbackappwfm();
          toast.success('Plataforma adicionada com sucesso!');
        }
      })
      .catch((error) => {
        toast.error('Erro ao adicionar a plataforma. Tente novamente mais tarde.');
      });

    props.onHide();
  };

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <h4 className="mb-0">Adicionar Contato</h4>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={6} className="form-group">
              <label className="labelTitle">Título</label>
              <input
                className="inputBoxs"
                type="text"
                name="title"
                maxLength={20}
                placeholder="Inserir nome"
                value={values.title}
                onChange={handleChangeValues}
              />
            </Col>
            <Col md={6} className="form-group">
            <label className="labelTitle">Descrição</label>
            <input
              className="inputBoxs"
              type="text"
              name="description"
              placeholder="Inserir descrição"
              maxLength={60}
              value={values.description}
              onChange={handleChangeValues}
            />
            </Col>
        </Row>
        <Row>
          <Col>
          <label className="labelTitle">Link</label>
            <input
              className="inputBoxs"
              type="text"
              name="link"
              placeholder="Inserir link"
              value={values.link}
              onChange={handleChangeValues}
            />
          </Col>
          <Col md={4} className='d-flex flex-column'>
          <label className="labelTitle">Secção</label>
          <select
          style={{appearance: 'menulist'}}
            className='inputBoxs'
            name="pos"
            value={values.pos}
            onChange={handleChangeValues}
          >
            <option value="">Selecionar secção</option>
            <option value="1">HFA - WebApp´s</option>
            <option value="2">WorkFlow Manager</option>
          </select>
          </Col>
          <Col md={2} className='d-flex flex-column'>
          <label className="labelTitle">Cor</label>
            <input
              type='color'
              name="color"
              value={values.color}
              onChange={handleColorChange}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button className="btnSave" onClick={handleClickButtonApp}>
          Criar webApp
        </button>
        <button className="btnCancel" onClick={props.onHide}>
          Cancelar
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAddApp;