import { configureStore } from '@reduxjs/toolkit';
import user from './modules/userSlice';



const persistedState = sessionStorage.getItem('userState')
  ? JSON.parse(sessionStorage.getItem('userState'))
  : {};

const store = configureStore({
  reducer: {
    user: user,
    //ADD HERE MORE RECUCERS IF I NEED
  },
  preloadedState: persistedState,
});

store.subscribe(() => {
  const state = store.getState();
  sessionStorage.setItem('userState', JSON.stringify(state));
});

export default store;
