import React, { useState, useEffect } from "react";
import Axios from "axios";
import APIHOST from "../../constants";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Title from "../../components/Title";
import Card from "../../components/Card/Card";
import BigCard from "../../components/Bigcard";
import CardContact from "../../components/Card/CardContact";
import ModalAddApp from "../../components/ModalAddApp/ModalAddApp";
import { RingLoader } from "react-spinners";
import { BiAlarmAdd } from "react-icons/bi";

import office from "../../assets/img/office.png";
import OCAS from "../../assets/img/OCAS.png";

import "../Home/Home.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Col from "react-bootstrap/esm/Col";

function Home() {
    const loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser"));
    const [modalCreateApp, setModalCreateApp] = useState(false);
    const [appsList, setAppsList] = useState([]);
    const [appsListWfm, setAppsListWfm] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const footer = {
        color: "lightgray",
        fontSize: "11px",
        marginBottom: "12px",
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [appsWfmResponse, appsResponse] = await Promise.all([
                    Axios.get(`${APIHOST}/appswfm`),
                    Axios.get(`${APIHOST}/apps`),
                ]);

                setAppsListWfm(appsWfmResponse.data);
                setAppsList(appsResponse.data);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    //callbacks
    const getAppsWfm = () => {
        Axios.get(`${APIHOST}/appswfm`).then((Response) => {
            setAppsListWfm(Response.data);
        });
    };

    //callbacks
    const getApps = () => {
        Axios.get(`${APIHOST}/apps`).then((Response) => {
            setAppsList(Response.data);
        });
    };

    return (
        <>
            <ModalAddApp
                show={modalCreateApp}
                onHide={() => setModalCreateApp(false)}
                backdrop="static"
                callbackapps={getApps}
                callbackappwfm={getAppsWfm}
            />
            {isLoading ? (
                <div className="loading-spinner">
                    <RingLoader size={150} color={"#123abc"} loading={isLoading} />
                </div>
            ) : (
                <div className="bg">
                    <Container style={{ maxWidth: "1400px" }}>
                        <div className="d-flex justify-content-between align-items-center ">
                            <Title titulo="HFA - WebApp´s" />
                            {loggedInUser?.rank >= 20 ? (
                                <button
                                    style={{ height: "fit-content" }}
                                    className="btnAddApp"
                                    onClick={() => setModalCreateApp(true)}
                                >
                                    Adicionar webApp
                                </button>
                            ) : (
                                ""
                            )}
                        </div>
                        <Row>
                            {/* <Col md={2} sm={12} className="p-0 try" style={{ minWidth: "201px" }}>
                                <button
                                    //onClick={() => setModalShowLoginToCreateTicket(true)} 
                                    className="card-body-b d-flex" style={{
                                        backgroundImage: 'linear-gradient(45deg, #5B247A, #1BCEDF)',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between'
                                    }}>
                                    <h4 className="card-title"><BiAlarmAdd style={{color: 'lightblue'}} size={86} /></h4>
                                    <div style={{color: 'black', textAlign: 'right' }} className="d-flex">Criar Tarefa</div>
                                </button>
                            </Col> */}
                            {appsList.map((value) => {
                                return (
                                    <Card
                                        key={value.id}
                                        id={value.id}
                                        href={value.link}
                                        title={value.title}
                                        description={value.description}
                                        style={{ backgroundColor: value.color }}
                                        color={value.color}
                                        callback={getApps}
                                    >
                                    </Card>
                                );
                            })}
                        </Row>
                    </Container>
                    <Container style={{ maxWidth: "1400px", marginTop: "75px" }}>
                        <Title titulo="WorkFlow Manager" />
                        <Row>
                            {appsListWfm.map((value) => {
                                return (
                                    <Card
                                        key={value.id}
                                        id={value.id}
                                        href={value.link}
                                        title={value.title}
                                        description={value.description}
                                        style={{ backgroundColor: value.color }}
                                        color={value.color}
                                        callback={getAppsWfm}
                                    ></Card>
                                );
                            })}
                        </Row>
                    </Container>
                    <Container style={{ maxWidth: "1400px", marginTop: "75px" }}>
                        <Title titulo="Informação Interna HFA" />
                        <Row>
                            <BigCard
                                href="https://www.hfa.pt"
                                title="ELECTRONIC IS EVERYWHERE AND WE BUILD IT"
                                style={{
                                    backgroundImage: `url(${office})`,
                                    backgroundSize: "cover",
                                }}
                            />
                            <BigCard
                                href="https://www.onecountryallsolutions.com/"
                                title="Grupo OCAS"
                                description="One Country, All Solutions.Providing the future."
                                style={{
                                    backgroundImage: `url(${OCAS})`,
                                    backgroundSize: "cover",
                                }}
                            />
                            <CardContact
                                style={{ backgroundColor: "#1998c5" }}
                                title="Contactos"
                                internalLink="/pages/Contatos"
                                description="Encontra todos os contatos Internos."
                            />
                        </Row>
                    </Container>
                    <Container className="d-flex justify-content-center">
                        <small style={footer}>Version 2.0.0</small>
                    </Container>
                </div >
            )
            }
        </>
    );
}

export default Home;
