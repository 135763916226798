import React, { useEffect, useState } from 'react';
import './ModalLoginTicketClip.css';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { saveAuthToken } from '../Contexts/auth';
import { toast } from 'react-toastify';
import { login } from "../../Store/modules/userSlice";
import { getAuthToken } from '../Contexts/auth';
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import Select from "react-select";

//OPTIONS TO HIDE ON FORM
export const listOfOptionsToHideOnForm = [
    "Início Intermédio da Intervenção",
    "Fim Intermédio da Intervenção",
    "Tempo da Manutenção Intermédia",
    "Tempo da Manutenção",
    "Responsável",
    "Início da Intervenção",
    "Fim da Intervenção",
    "created_at",
    "inicioMicro",
    "FimMicro",
    "user_alter",
    "user_created",
    "Quem abriu"
]

function ModalLoginTicketClip(props) {
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const user = useSelector((state) => state.user.data);
    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState("")

    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState("")
    const [listOfListsOptions, setListOfListsOptions] = useState([])
    const [nameSection, setNameSection] = useState("")
    const [rowsSection, setRowsSection] = useState({})
    const [nameHiddenSection, setNameHiddenSection] = useState("")
    const [rowsHiddenSection, setRowsHiddenSection] = useState({})
    const [nameExtraRows, setNameExtraRows] = useState("")
    const [extraRows, setExtraRows] = useState({})
    const [listFinalOfUsers, setListFinalOfUsers] = useState([])
    const [listFinalOfDepartments, setListFinalOfDepartments] = useState([])
    const [isChecked, setIsChecked] = useState(false);
    const [projecteSelectedFrom, setProjecteSelectedFrom] = useState();
    const [typeprojectforuse, settypeprojectforuse] = useState([])
    const [showPassword, setShowPassword] = useState(false);

    //Standard ticket
    const [titleTask, setTitleTask] = useState("")
    const [startDateForTask, setStartDateForTask] = useState(new Date().toISOString().split('T')[0])
    const [endDateForTask, setEndDateForTask] = useState("")
    const [selectedPriority, setSelectedPriority] = useState("")
    const [selectedListOfusers, setSelectedListOfusers] = useState([])
    const [description, setDescription] = useState("")

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    //FUNCTION FOR USERS TO LOG IN TO TICKETCLIP"
    function handleSubmit(event) {
        event.preventDefault();

        // Prepare user credentials data in JSON format
        let data = JSON.stringify({
            email: email,
            password: password,
        });

        // Configuration for the authentication token request
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `http://10.0.5.50:8000/auth/obtain-token/`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        // Make a request to obtain the authentication token
        axios
            .request(config)
            .then((res) => {
                // Check if the request was successful (status code 200)
                if (res.status === 200) {
                    // Parse the response JSON
                    let rjson = res.data;

                    // Check if the response contains the "token" key
                    if (!rjson.hasOwnProperty("token")) {
                        return console.log("Body is missing token key");
                    }

                    // Extract the obtained token
                    let token = rjson.token;
                    saveAuthToken(token);  // Save the authentication token

                    // Configuration for the user information request
                    let userConfig = {
                        method: "GET",
                        maxBodyLength: Infinity,
                        url: `http://10.0.5.50/api/user/?email=${email}`,
                        headers: {
                            Authorization: `token ${token}`,
                        },
                    };

                    // Make a request to obtain user information using the obtained token
                    axios
                        .request(userConfig)
                        .then((res) => {
                            // Check if the user information request was successful
                            if (res.status === 200) {
                                // Parse the user information response JSON
                                let rjson = res.data;

                                // Check if the response contains the "result" key
                                if (!rjson.hasOwnProperty("result")) {
                                    return console.log("Body is missing result key");
                                }

                                // Extract user information
                                let userId = rjson.result[0].id;
                                let userRank = rjson.result[0].rank;
                                let userEmail = rjson.result[0].email;
                                let firstName = rjson.result[0].first_name;

                                // Dispatch the login action with user information
                                dispatch(
                                    login({
                                        data: {
                                            name: firstName,
                                            id: userId,
                                            rank: userRank,
                                            email: userEmail,
                                        },
                                    })
                                );
                                // Perform any additional navigation or actions if needed
                                // navigate("/Pages/homePage/");
                            } else {
                                setError("Login falhado. tente novamente");
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            setError("Login falhado. tente novamente");
                        });
                } else {
                    setError("Login falhado. tente novamente");
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error("Login falhado. tente novamente");
            });
    }

    // GET ProjectAll
    const getAllProjects = async () => {
        let token = getAuthToken();

        const ListProject = {
            method: "get",
            maxBodyLength: Infinity,
            url: `http://10.0.5.50//api/project/project/?name__istartswith=Suporte`,
            headers: {
                Authorization: `token ${token}`,
            },
        };

        try {
            const response = await axios.request(ListProject);
            if (response.status === 200) {
                setProjects(response.data.result);
                console.log(response.data.result);
            } else {
                throw new Error("Failed to get project");
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Function to getListOfListsOptions
    const getListOfListsOptions = async () => {
        let token = getAuthToken();

        const listOfLists = {
            method: "get",
            maxBodyLength: Infinity,
            url: `http://10.0.5.50/api/project/customentity/`,
            headers: {
                Authorization: `token ${token}`,
            },
        };

        try {
            const response = await axios.request(listOfLists);
            if (response.status === 200) {
                setListOfListsOptions(response.data.result);
                //console.log(response.data.result);
            } else {
                throw new Error("Failed to get listOfListsOptions List");
            }
        } catch (error) {
            //toast.error(`Failed to get listOfListsOptions List ${error}`);
            console.log(error);
        }
    };

    // Function to getListOfUsers
    const getListOfUsers = async () => {
        let token = getAuthToken();

        const listOfUsers = {
            method: "get",
            maxBodyLength: Infinity,
            url: `http://10.0.5.50/api/user/?pr_order_by=first_name&pr_order_dir=asc`,
            headers: {
                Authorization: `token ${token}`,
            },
        };

        try {
            const response = await axios.request(listOfUsers);
            if (response.status === 200) {
                setListFinalOfUsers(response.data.result);
                //console.log(response.data.result);
            } else {
                throw new Error("Failed to get listOfListsOptions List");
            }
        } catch (error) {
            //toast.success(`Failed to get listOfListsOptions List ${error}`);
            console.log(error);
        }
    };

    // Function to getDepartments
    const getDepartments = async () => {
        let token = getAuthToken();

        const listOfDeparments = {
            method: "get",
            maxBodyLength: Infinity,
            url: `http://10.0.5.50/api/department/department/`,
            headers: {
                Authorization: `token ${token}`,
            },
        };

        try {
            const response = await axios.request(listOfDeparments);
            if (response.status === 200) {
                setListFinalOfDepartments(response.data.result);
                //console.log(response.data.result);
            } else {
                throw new Error("Failed to get listOfListsOptions List");
            }
        } catch (error) {
            //toast.success(`Failed to get listOfListsOptions List ${error}`);
            console.log(error);
        }
    };

    // GET ProjectType
    const getProjectSelected = async () => {
        let token = getAuthToken();

        const ListProject = {
            method: "get",
            maxBodyLength: Infinity,
            url: `http://10.0.5.50/api/project/project/?id=${selectedProject}`,
            headers: {
                Authorization: `token ${token}`,
            },
        };

        try {
            const response = await axios.request(ListProject);
            if (response.status === 200) {
                setProjecteSelectedFrom(response.data.result);
                //console.log(response.data.result);
            } else {
                throw new Error("Failed to get project type");
            }
        } catch (error) {
            console.log(error);
        }
    };

    // GET ProjectType
    const getProjectType = async () => {
        let token = getAuthToken();

        if (projecteSelectedFrom && projecteSelectedFrom[0] && projecteSelectedFrom[0].project_type) {
            const ListProject = {
                method: "get",
                maxBodyLength: Infinity,
                url: `http://10.0.5.50/api/project/projecttype/?id=${projecteSelectedFrom[0].project_type}`,
                headers: {
                    Authorization: `token ${token}`,
                },
            };

            try {
                const response = await axios.request(ListProject);
                if (response.status === 200) {
                    settypeprojectforuse(response.data.result);
                } else {
                    throw new Error("Failed to get project type");
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            //console.log("projecteSelectedFrom or project_type is undefined or null");
        }
    };


    useEffect(() => {
        if (selectedProject) {
            getProjectSelected()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProject]);

    useEffect(() => {
        getProjectType()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projecteSelectedFrom]);

    useEffect(() => {
        if (user?.id) {
            getAllProjects()
            getListOfListsOptions()
            getListOfUsers()
            getDepartments()
        } else {
            //console.log("User not logged in");
        }

        return () => {
            setProjects([]);
            setSelectedProject("");
            setListOfListsOptions([]);
            setRowsSection({});
            setNameHiddenSection("")
            setRowsHiddenSection({});
            setListFinalOfUsers([]);
            setListFinalOfDepartments([]);
            setIsChecked(false);
            setNameSection("")
            setNameExtraRows("")
            setExtraRows({})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.onHide, user]);

    // Handle changes in sections
    const handleChangeSection = (rowIndex, value, isVisible) => {
        //console.log("isVisible", isVisible);
        // eslint-disable-next-line eqeqeq
        if (isVisible == "visible") {
            const updatedRows = [...rowsSection];
            updatedRows[rowIndex].value = value;
            setRowsSection(updatedRows);
            //console.log("rowsSection", rowsSection);
        }
        // eslint-disable-next-line eqeqeq
        else if (isVisible == "invisible") {
            const updatedRows = [...rowsHiddenSection];
            updatedRows[rowIndex].value = value;
            setRowsHiddenSection(updatedRows);
            //console.log("rowsHiddenSection", rowsHiddenSection);
        }
    };

    //map all things to create form
    const renderInputFields = (labels, isVisible) => {
        return labels.map((label, index) => {
            const { name, type, options } = label;
            const findValueListByEntity = (options) => {
                if (listOfListsOptions) {
                    const entity = listOfListsOptions.find((item) => item.entity === options);
                    return entity ? entity.value_list : [];
                } else {
                    return [];
                }
            };
            if (!listOfOptionsToHideOnForm.includes(name)) {
                switch (type) {
                    case "text":
                    case "textarea":
                        const colSize = type === "textarea" ? 12 : 4;
                        return (
                            <Col className="mt-2" key={index} sm={12} md={colSize}>
                                <label style={{ letterSpacing: '0.5px', fontSize: '14px' }}>{name}</label>
                                {type === "text" ? (
                                    <input className='inputBoxs' type="text" placeholder={name} onChange={(e) => { handleChangeSection(index, e.target.value, isVisible) }} />
                                ) : (
                                    <textarea className='inputBoxs' placeholder={name} rows={4} onChange={(e) => { handleChangeSection(index, e.target.value, isVisible) }}></textarea>
                                )}
                            </Col>
                        );
                    case "date":
                        return (
                            <Col className="mt-2" key={index} sm={12} md={4}>
                                <label style={{ letterSpacing: '0.5px', fontSize: '14px' }}>{name}</label>
                                <input className='inputBoxs' type="date" placeholder={name} onChange={(e) => { handleChangeSection(index, e.target.value, isVisible) }} />
                            </Col>
                        );
                    case "select":
                        const valueList = findValueListByEntity(options);
                        return (
                            <Col className="mt-2" key={index} sm={12} md={4}>
                                <label style={{ letterSpacing: '0.5px', fontSize: '14px' }}>{name}</label>
                                {options === "users" ? (
                                    <select
                                        className='inputBoxs'
                                        onChange={(e) => {
                                            handleChangeSection(index, e.target.value, isVisible);
                                        }}
                                    >
                                        <option value="">{`Selecionar ${name}`}</option>
                                        {listFinalOfUsers.map((value, valueIndex) => (
                                            <option key={valueIndex} defaultValue={`${value.first_name} ${value.last_name}`}>
                                                {`${value.first_name} ${value.last_name}`}
                                            </option>
                                        ))}
                                    </select>
                                ) : options === "Departamentos" ? (
                                    <select
                                        className='inputBoxs'
                                        onChange={(e) => { handleChangeSection(index, e.target.value, isVisible) }}
                                    >
                                        <option value="">{`Selecionar ${name}`}</option>
                                        {listFinalOfDepartments.map((value, valueIndex) => (
                                            <option key={valueIndex} defaultValue={value.name}>
                                                {value.name}
                                            </option>
                                        ))}
                                    </select>
                                ) : (
                                    <select
                                        className='inputBoxs'
                                        onChange={(e) => { handleChangeSection(index, e.target.value, isVisible) }}
                                    >
                                        <option value="">{`Selecionar ${name}`}</option>
                                        {valueList.map((value, valueIndex) => (
                                            <option key={valueIndex} defaultValue={value}>
                                                {value}
                                            </option>
                                        ))}
                                    </select>
                                )
                                }
                            </Col >
                        );
                    case "checkbox":
                        return (
                            <Col className="mt-2" md={6} key={index} style={{ alignSelf: 'center' }}>
                                <label className="d-flex" style={{ letterSpacing: '0.5px', fontSize: '14px' }}>
                                    <input
                                        style={{ height: '16px', appearance: 'auto', width: 'auto', alignItems: 'center', marginRight: '8px' }}
                                        type="checkbox"
                                        value={isChecked}
                                        onChange={(e) => {
                                            handleChangeSection(index, !isChecked, isVisible)
                                            setIsChecked(!isChecked)
                                        }}
                                    />
                                    {name}
                                </label>
                            </Col>
                        );
                    default:
                        return null;
                }
            }
            return null
        });
    };

    useEffect(() => {
        setNameSection(typeprojectforuse?.[0]?.ticket_template?.[0]?.section?.section_name);
        setRowsSection(typeprojectforuse?.[0]?.ticket_template?.[0]?.section?.labels);
        setNameHiddenSection(typeprojectforuse?.[0]?.ticket_template?.[0]?.hidden_section?.[0]?.hidden_section_a.section_name);
        setRowsHiddenSection(typeprojectforuse?.[0]?.ticket_template?.[0]?.hidden_section?.[0]?.hidden_section_a.labels);
        setNameExtraRows(typeprojectforuse?.[0]?.ticket_template?.[0]?.extra_section?.[0]?.extra_section_a?.section_name);
        setExtraRows(typeprojectforuse?.[0]?.ticket_template?.[0]?.extra_section?.[0]?.extra_section_a?.labels);
    }, [typeprojectforuse, rowsHiddenSection]);

    // CREATE TICKET
    const createTicket = async () => {
        let token = getAuthToken();
        let data = JSON.stringify({
            created_by: user.id,
            project_id: selectedProject,
            state_index: 0,
            custom_fields: {
                section: {
                    section_name: nameSection,
                    labels: rowsSection,
                },
                hidden_section: [
                    {
                        hidden_section_a: {
                            section_name: nameHiddenSection,
                            labels: rowsHiddenSection,
                        },
                    },
                ],
                extra_section: [
                    {
                        extra_section_a: {
                            section_name: nameExtraRows,
                            labels: extraRows,
                        },
                    },
                ],
            },
        });

        const createTicket = {
            method: "post",
            maxBodyLength: Infinity,
            url: `http://10.0.5.50/api/ticket/ticket/`,
            headers: {
                Authorization: `token ${token}`,
                "Content-Type": "application/json",
            },
            data: data,
        };

        try {
            const response = await axios.request(createTicket);
            if (response.status === 200) {
                toast.success(`Tarefa criada com sucesso`);
                props.onHide();
                //props.callback()
            } else {
                throw new Error("Failed to create task");
            }
        } catch (error) {
            toast.error(
                `Erro ao criar a tarefa com o erro ${error}`
            );
            console.log(error);
        }
    };

    // CREATE TICKET
    const createStandardTicket = async () => {
        let token = getAuthToken();
        let data = JSON.stringify({
            title: titleTask,
            description: description,
            start_date: startDateForTask,
            end_date: endDateForTask,
            priority: selectedPriority,
            state_index: 0,
            created_by: user.id,
            project_id: selectedProject,
            user_list: selectedListOfusers,
            custom_fields: {}
        });

        const createTicket = {
            method: "post",
            maxBodyLength: Infinity,
            url: `http://10.0.5.50/api/ticket/ticket/`,
            headers: {
                Authorization: `token ${token}`,
                "Content-Type": "application/json",
            },
            data: data,
        };

        try {
            const response = await axios.request(createTicket);
            if (response.status === 200) {
                toast.success(`Tarefa criada com sucesso`);
                props.onHide();
            } else {
                throw new Error("Failed to create task");
            }
        } catch (error) {
            toast.error(
                `Erro ao criar a tarefa com o erro ${error}`
            );
            console.log(error);
        }
    };

    // useEffect(() => {
    //     console.log(selectedProject);
    // }, [selectedProject]);


    return (
        <Modal
            {...props}
            size={user ? "lg" : "sm"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <h4 className='mb-0' style={{ letterSpacing: '0.5px' }}><strong style={{ color: '#1d71b8' }}>Ticket</strong>Clip {!user ? ("Login") : ("criar ticket")}</h4>
            </Modal.Header>
            {user ? (
                // USER LOGGED ON TICKETCLIP
                <>
                    <Modal.Body>
                        <Row className="mt-2">
                            <Col sm={12} md={6}>
                                <label style={{ letterSpacing: '0.5px', fontSize: '14px' }}>Escolher suporte que pretende</label>
                                <select
                                    className="inputBoxs"
                                    value={selectedProject.id}
                                    onChange={(e) => setSelectedProject(e.target.value)}
                                >
                                    <option value="" >
                                        Selecionar suporte
                                    </option>
                                    {projects
                                        .map((values) => (
                                            <option key={values.id} value={values.id}>
                                                {values.name}
                                            </option>
                                        ))}
                                </select>
                            </Col>
                        </Row>
                        {selectedProject === "" ? ("") : (
                            <Row>
                                {typeprojectforuse?.[0]?.ticket_template?.[0] !== undefined ? (
                                    <>
                                        <Row style={{ margin: '0' }}>
                                            {typeprojectforuse?.[0].ticket_template &&
                                                typeprojectforuse?.[0].ticket_template[0] &&
                                                typeprojectforuse?.[0].ticket_template[0].section &&
                                                renderInputFields(
                                                    typeprojectforuse?.[0].ticket_template[0].section.labels, "visible"
                                                )}
                                        </Row>
                                        {isChecked === true ? (
                                            <>
                                                <Row className="mt-2">
                                                    <hr />
                                                </Row>
                                                <Row>
                                                    {typeprojectforuse?.[0].ticket_template &&
                                                        typeprojectforuse?.[0].ticket_template[0] &&
                                                        typeprojectforuse?.[0].ticket_template[0].section &&
                                                        typeprojectforuse?.[0].ticket_template[0].hidden_section &&
                                                        typeprojectforuse?.[0].ticket_template[0].hidden_section[0].hidden_section_a &&
                                                        renderInputFields(
                                                            typeprojectforuse?.[0].ticket_template[0].hidden_section[0].hidden_section_a.labels, "invisible"
                                                        )}
                                                </Row>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        <Row>
                                            <div className=" d-flex justify-content-end ">
                                                <button className="btnCancel" onClick={props.onHide}>
                                                    Cancelar
                                                </button>
                                                <button
                                                    style={{ marginLeft: '8px' }}
                                                    className="btnSave"
                                                    onClick={createTicket}
                                                >
                                                    Criar tarefa
                                                </button>
                                            </div>
                                        </Row>
                                    </>
                                ) : (
                                    <>
                                        <Row className="mt-2">
                                            <Col style={{ fontSize: "0.875rem" }} sm={12} md={12}>
                                                <label>Selecionar Utilizador</label>
                                                <Select
                                                    placeholder="Lista de utilizadores"
                                                    isMulti
                                                    options={
                                                        listFinalOfUsers &&
                                                            listFinalOfUsers.length ?
                                                            listFinalOfUsers.map((item) => ({
                                                                value: item.id,
                                                                label: `${item.first_name || ''} ${item.last_name || ''}`.trim(),
                                                            }))
                                                            : []
                                                    }
                                                    onChange={(selectOption) => {
                                                        const selectedUserIds = selectOption.map((option) => option.value);
                                                        setSelectedListOfusers(selectedUserIds);
                                                    }}
                                                />
                                            </Col>
                                            <Col className="mt-4" style={{fontSize: '14px'}} sm={12} md={8}>
                                                <label>Título Tarefa</label>
                                                <input className='inputBoxs' type="text" onChange={(e) => setTitleTask(e.target.value)}
                                                />
                                            </Col>
                                            <Col className="mt-4" sm={12} md={4} style={{fontSize: '14px'}}>
                                                <label>Prioridade</label>
                                                <select className='inputBoxs' onChange={(e) => setSelectedPriority(e.target.value)}>
                                                    <option value="">Selecionar</option>
                                                    {listOfListsOptions.find(option => option.entity === "Prioridades Gerais")?.value_list.map((value) => (
                                                        <option value={value}>{value}</option>
                                                    ))}
                                                </select>
                                            </Col>
                                            <Col className="mt-4" sm={12} md={12} style={{fontSize: '14px'}}>
                                                <label>Descrição</label>
                                                <textarea className='inputBoxs' type="text" rows={4} onChange={(e) => setDescription(e.target.value)} />
                                            </Col>
                                            <Col className="mt-4" sm={12} md={4} style={{fontSize: '14px'}}>
                                                <label>Data Ínicio</label>
                                                <input className='inputBoxs' type="date" disabled value={startDateForTask} onChange={(e) => setStartDateForTask(e.target.value)} />
                                            </Col>
                                            <Col className="mt-4" sm={12} md={4} style={{fontSize: '14px'}}>
                                                <label>Data Fim</label>
                                                <input className='inputBoxs' type="date" onChange={(e) => setEndDateForTask(e.target.value)} />
                                            </Col>
                                        </Row>
                                        <Row>
                                    <div className="mt-5 d-flex justify-content-end ">
                                        <button className="btnCancel" onClick={props.onHide}>
                                            Cancelar
                                        </button>
                                        <button
                                            className="btnSave" style={{marginLeft: '12px'}}
                                            onClick={createStandardTicket}
                                        >
                                            Criar tarefa
                                        </button>
                                    </div>
                                </Row>
                                    </>
                                )}
                            </Row>
                        )}
                    </Modal.Body>
                </>
            ) : (
                // SHOW THIS IF THE VARIABLE "USER" EMPTY / USER NOT LOGEED ON TICKETCLIP
                <>
                    <Modal.Body>
                        <div className='d-grid'>
                            <Row>
                                <Col sm={12}>
                                    <label
                                        style={{ fontSize: '14px', marginBottom: '16px', letterSpacing: '0.3px' }}
                                    >
                                        Para conseguir criar um ticket tem que fazer login com as credenciais do TicketClip</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <div className='mb-1'>
                                        <input
                                            className='inputBoxs'
                                            type='email'
                                            placeholder='Inserir email'
                                            autoFocus
                                            autoComplete
                                            required
                                            onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e)}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <div className='mb-2' style={{ position: 'relative' }}>
                                        <input
                                            className='inputBoxs'
                                            type={showPassword ? 'text' : 'password'}
                                            required
                                            placeholder='Inserir password'
                                            autoComplete='true'
                                            onChange={(e) => setPassword(e.target.value)}
                                            onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e)}
                                        />
                                        <button type="button" style={{ position: 'absolute', top: '6px', right: '16px', background: 'none', border: 'none' }} onClick={handleTogglePassword}>
                                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btnSave' onClick={(e) => handleSubmit(e)}
                        >
                            Entrar
                        </button>
                        <button className='btnCancel' onClick={props.onHide}>Cancelar</button>
                    </Modal.Footer>
                </>
            )}
        </Modal>
    );
}

export default ModalLoginTicketClip;
