import React, { useState } from "react";
import "./ModalEditApp.css";
import Modal from "react-bootstrap/Modal";
import Axios from "axios";
import { toast } from "react-toastify";
import APIHOST from "../../constants";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";

function ModalEditApp(props) {
  const [editValues, setEditValues] = useState({
    id: props.id,
    title: props.title,
    description: props.description,
    link: props.link,
    color: props.color || "", // Assign an empty string as the default color if props.color is undefined
  });


  const handleEditApp = () => {
    Axios.put(`${APIHOST}/editApp`, {
      id: editValues.id,
      title: editValues.title,
      description: editValues.description,
      link: editValues.link,
      color: editValues.color,
    })
      .then((response) => {
        if (response.status === 200) {
          props.callback();
          toast.success("WebApp editada com sucesso!");
        }
      })
      .catch((error) => {
        toast.error("Erro ao editar a WebApp. Tente novamente mais tarde.");
      });

    props.onHide();
  };

  const handleChangeValues = (event) => {
    const { id, value } = event.target;
    if (id === 'color') {
      setEditValues((prevValues) => ({
        ...prevValues,
        color: value,
      }));
    } else {
      setEditValues((prevValues) => ({
        ...prevValues,
        [id]: value,
      }));
    }
  };


  const handleDeleteCard = () => {
    Axios.delete(`${APIHOST}/delcard/${editValues.id}`)
      .then((response) => {
        if (response.status === 200) {
          //console.log(response.data); // logs the response data
          props.callback(); // updates the contact list
          toast.success('Cartão apagado com sucesso!'); // show success notification
        }
      })
      .catch((error) => {
        console.log(error); // logs any errors that occur during the delete request
      });
    props.onHide(); // hides the modal after the delete request is sent
  };


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <h4 className="mb-0">Editar WebApp</h4>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={6} className="form-group">
            <label className="labelTitle">Título</label>
            <input
              className="inputBoxs"
              type="text"
              id="title"
              name="title"
              maxLength={20}
              defaultValue={props.title}
              onChange={handleChangeValues}
            />
          </Col>
          <Col md={6} className="form-group">
            <label className="labelTitle">Descrição</label>
            <input
              className="inputBoxs"
              type="text"
              id="description"
              name="description"
              maxLength={60}
              defaultValue={props.description}
              onChange={handleChangeValues}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="labelTitle">Link</label>
            <input
              className="inputBoxs"
              type="text"
              name="link"
              id="link"
              defaultValue={props.link}
              onChange={handleChangeValues}
            />
          </Col>
          <Col md={6} className="d-flex flex-column">
            <label className="labelTitle">Cor</label>
            <input
              type="color"
              name="color"
              id="color"
              defaultValue={props.color}
              onChange={handleChangeValues}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button className="btnSave" onClick={handleEditApp}>
          Editar
        </button>
        <button className="btnDeleteContact" onClick={handleDeleteCard}>
          Apagar
        </button>
        <button className="btnCancel" onClick={props.onHide}>
          Cancelar
        </button>
      </Modal.Footer>
    </Modal>
  );
}
export default ModalEditApp;
