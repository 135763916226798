import React from "react";
import { useNavigate } from "react-router-dom";
//import withAuth from '../Contexts/withAuth';
import "./ButtonOut.css";
import { GrLogout } from "react-icons/gr";
import { useDispatch } from "react-redux";
import { logout } from "../../Store/modules/userSlice";
import { removeAnonymousUser, removeLoggedInUser } from "../Contexts/auth";

const ICON_SIZE = 14;

function LogoutButton({ callback }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleLogout() {
    // Remove the logged-in user's information from session storage
    removeLoggedInUser()
    dispatch(logout());
    callback();
    removeAnonymousUser()

    // Navigate back to the login page
    navigate("/");
    window.location.reload();
  }

  //verifica se o utilizador está logado
  const loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser"));

  return (
    <div>
      {loggedInUser && (
        <button className="btn-out" onClick={handleLogout}>
          <GrLogout style={{marginRight: '8px'}} size={ICON_SIZE} /> Logout
        </button>
      )}
    </div>
  );
}

export default LogoutButton;
