import React from "react";
import { FaSignOutAlt, FaThLarge, FaBars, FaUserFriends } from "react-icons/fa";
import { HiUserGroup } from "react-icons/hi";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import "../style/sidebar.css";
import { removeAnonymousUser } from "./Contexts/auth";

const ICON_SIZE = 20;

function Navbar({ visible, show }) {
  const navigate = useNavigate();
  const loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser"));
  const anonymousUser = JSON.parse(sessionStorage.getItem("anonymousUser"));

  const handleClick = () => {
    show(!visible);
    navigate(""); // Use navigate function here
    removeAnonymousUser();
    window.location.reload();
  };

  return (
    <>
      {anonymousUser || loggedInUser ? (
        <div className="btn-side">
          <button className="mobile-nav-btn" onClick={() => {
            show(!visible)
          }}>
            <FaBars size={24} />
          </button>
        </div>) : (<></>)}
      <nav className={!visible ? "navbar" : ""}>
        <div>
          <div className="links nav-top">
            <div style={{ height: "20px" }}>
              <NavLink
                onClick={() => show(!visible)}
                to="/pages/Home"
                className="nav-link"
              >
                <FaThLarge size={ICON_SIZE} />
                <span>HomePage</span>
              </NavLink>
              <NavLink
                onClick={() => show(!visible)}
                to="/pages/Contatos"
                className="nav-link"
              >
                <FaUserFriends size={ICON_SIZE} />
                <span>Contatos</span>
              </NavLink>
              {loggedInUser && loggedInUser.rank >= 20 && (
                <NavLink
                  onClick={() => show(!visible)}
                  to="/pages/UsersPage"
                  className="nav-link"
                >
                  <HiUserGroup size={ICON_SIZE} />
                  <span>Utilizadores</span>
                </NavLink>
              )}
            </div>
          </div>
        </div>
        {!loggedInUser ? (
          <div className="links">
            <NavLink
              onClick={handleClick}
              to="/"
              className="nav-link"
            >
              <FaSignOutAlt size={ICON_SIZE} />
              <span>Login</span>
            </NavLink>
          </div>
        ) : null}
      </nav>
    </>
  );
}

export default Navbar;
