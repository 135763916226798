import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import "../Nav/Nav.css";
import logo from "../../assets/img/logo.svg";
import UserComponent from "../UserComponent/UserComponent";
import ModalLoginTicketClip from "../ModalLoginTicketClip/ModalLoginTicketClip";

function Top() {
  const anonymousUser = JSON.parse(sessionStorage.getItem("anonymousUser"));
  const loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser"));
  const [modalShowLoginToCreateTicket, setModalShowLoginToCreateTicket] = useState("");

  return (
    <>
      <ModalLoginTicketClip
        show={modalShowLoginToCreateTicket}
        onHide={() => setModalShowLoginToCreateTicket(false)}
        backdrop="static"
      />
      {anonymousUser || loggedInUser ? (
        <div>
          <div className="head align-items-center d-flex fixed-top justify-content-lg-between mLeftRight">
            <Link to="/pages/Home">
              <img className="Logo-style" src={logo} alt="logo" />
            </Link>
            {/* {loggedInUser && (
              <button
                className="btnCreateSupportTicket"
                onClick={() => setModalShowLoginToCreateTicket(true)}
              >
                Criar Tarefa
              </button>
            )} */}
            <UserComponent />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default Top;
