import React from "react";
import "./ModalEditRank.css";
import Modal from "react-bootstrap/Modal";
import Axios from "axios";
import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { toast } from "react-toastify";
import APIHOST from "../../constants";
import { rankList } from "../Functions/utils";

function ModalEditRank(props) {
  const [newRank, setNewRank] = useState("");

  const handleClickButton = () => {
    Axios.put(`${APIHOST}/editRank`, {
      id: props.info.id,
      email: props.info.email,
      rank: newRank,
    }).then((response) => {
      if (response.status === 200) {
        //console.log(response);
        props.callback();
        toast.success("Rank atualizado com sucesso"); // show success notification
      }
    });
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <h4 className="mb-0">Editar Rank</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="d-grid">
          <Row>
            <Col md={12}>
              <div className="d-block">
                <label className="labelTitle mb-1">{props.info.email}</label>
                <select
                className="optDeparts"
                  onChange={(e) => setNewRank(e.target.value)}
                  defaultValue={props.info.rank}
                >
                  {rankList.map((ranks) => (
                    <option value={ranks.value}>{ranks.name}</option>
                  ))}
                </select>
              </div>
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btnSave" onClick={() => handleClickButton()}>
          Editar
        </button>
        <button className="btnCancel" onClick={props.onHide}>
          Cancelar
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalEditRank;
