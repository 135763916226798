import "../Card/Card.css";
import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaUserEdit } from "react-icons/fa";
import ModalEditApp from "../ModalEditApp/ModalEditApp";

const ICON_SIZE = 20;

const Card = (props) => {
  const loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser"));
  const [modalShowEdit, setModalShowEdit] = useState(false);

  return (
    <>
      <ModalEditApp
        show={modalShowEdit}
        onHide={() => setModalShowEdit(false)}
        backdrop="static"
        id={props.id}
        title={props.title}
        description={props.description}
        link={props.href} // Pass props.href as the link prop
        color={props.color}
        callback={props.callback}
      />

      <Col md={2} sm={12} className="p-0 try" style={{ minWidth: "201px" }}>
        <div className="card-body" style={props.style}>
          <div className="d-flex justify-content-between align-items-start ">
            <h4 className="card-title">{props.title}</h4>
            {loggedInUser?.rank >= 20 ? (
              <button
                className="btnDelete"
                onClick={() => setModalShowEdit(true)}
              >
                <FaUserEdit
                  size={ICON_SIZE}
                  className="icon"
                  style={{ color: "white" }}
                />
              </button>
            ) : (
              ""
            )}
          </div>
          <p>{props.description}</p>
          <a
            target="_blank"
            rel="noreferrer"
            href={props.href}
            className="g-button"
          >
            Entrar
          </a>
        </div>
      </Col>
    </>
  );
};

export default Card;
