import React, { useState } from "react";
import Navbar from "./components/Sidebar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./style/index.css";
import Home from "./pages/Home";
import Contatos from "./pages/Contatos";
import Login from "./pages/Login/Login";
import Top from "./components/Nav";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UsersPage from "./pages/UsersPage/usersPage";
import { Provider } from "react-redux";
import store from "./Store/store";

function App() {
  const [navVisible, showNavbar] = useState(false);

  return (
    <>
      <BrowserRouter>
        <Provider store={store}>
          <div className="App">
            <ToastContainer />
            <Top />
            <Navbar visible={navVisible} show={showNavbar} />
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/pages/Home" element={<Home />} />
              <Route path="/pages/Contatos" element={<Contatos />} />
              <Route path="/pages/UsersPage" element={<UsersPage />} />
            </Routes>
          </div>
        </Provider>
      </BrowserRouter>
    </>
  );
}

export default App;
