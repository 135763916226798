// Function to map rank numbers to names
export const getRankName = (rank) => {
    switch (rank) {
      case 10:
        return "Utilizador";
      case 20:
        return "Administrador";
      default:
        return "rank não identificado";
    }
  };

  export const rankList = [
    { value: 10, name: "Utilizador" },
    { value: 20, name: "Administrador" },
  ];